.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.currencySelectWrapper {
  min-width: 100px;
  margin-left: 2rem;
}

.currencySelect {
  width: 100%;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
