.root {
  flex-grow: 1;
}

.title {
  flex-grow: 1;
}

.logo {
  text-decoration: none;
  color: inherit;
}

.footerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
